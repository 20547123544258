<template>
  <div id="app">
    <!-- 导航栏 -->
    <zg-header></zg-header>

    <!-- 主区域 -->
    <el-main class="main">
      <div class="menu">
        <el-menu :unique-opened="true" :default-active="currentPath" :router="true">
          <!-- 主页 -->
          <el-menu-item index="/index">
            <i class="el-icon-s-home"></i>
            <span>概况</span>
          </el-menu-item>
          
          <!-- 商城管理 -->
          <el-submenu index="shop">
            <template slot="title">
              <i class="el-icon-shopping-bag-2"></i>
              <span>商城</span>
            </template>
            <!-- <el-menu-item index="/shop/renovation">
              <i class="el-icon-brush"></i>
              <span>商城装修</span>
            </el-menu-item> -->
            <el-menu-item index="/shop/goods/list">
              <i class="el-icon-takeaway-box"></i>
              <span>商品管理</span>
            </el-menu-item>
            <el-menu-item index="/shop/order/list">
              <i class="el-icon-document-checked"></i>
              <span>订单管理</span>
            </el-menu-item>
          </el-submenu>

          <!-- 文件夹 -->
          <el-menu-item index="/folder/list">
            <i class="el-icon-folder"></i>
            <span>文件夹</span>
          </el-menu-item>

          <!-- 营销管理 -->
          <el-submenu index="active">
            <template slot="title">
              <i class="el-icon-attract"></i>
              <span>营销管理</span>
            </template>
            <el-menu-item index="/card/edit">
              <i class="el-icon-bank-card"></i>
              <span>名片装修</span>
            </el-menu-item>
            <el-menu-item @click="$winRedirectPage(1, '#/site/edit')">
              <i class="el-icon-bank-card"></i>
              <span>官网装修</span>
            </el-menu-item>
            <el-menu-item index="/article/list">
              <i class="el-icon-bank-card"></i>
              <span>文章营销</span>
            </el-menu-item>
            <el-menu-item index="/poster/poster">
              <i class="el-icon-notebook-2"></i>
              <span>获客海报</span>
            </el-menu-item>
            <el-menu-item index="/brochure/list">
              <i class="el-icon-notebook-2"></i>
              <span>企业宣传册</span>
            </el-menu-item>
            <el-menu-item index="/case/list">
              <i class="el-icon-notebook-2"></i>
              <span>案例库</span>
            </el-menu-item>
            <el-menu-item index="/task/list">
              <i class="el-icon-notebook-2"></i>
              <span>任务管理</span>
            </el-menu-item>
          </el-submenu>

          <!-- 名片广场 -->
          <el-menu-item index="/square/index">
            <i class="el-icon-position"></i>
            <span>名片广场</span>
          </el-menu-item>
          <!-- 商机中心 -->
          <el-menu-item index="/opportunity/index">
            <i class="el-icon-aim"></i>
            <span>商机中心</span>
          </el-menu-item>
          <!-- 员工 -->
          <el-submenu index="member">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>员工管理</span>
            </template>
            <el-menu-item index="/member/grounp">
              <i class="el-icon-pie-chart"></i>
              <span>团队管理</span>
            </el-menu-item>
            <el-menu-item index="/member/role">
              <i class="el-icon-document-copy"></i>
              <span>角色管理</span>
            </el-menu-item>
          </el-submenu>

          <!-- 客户管理 -->
          <el-submenu index="customer">
            <template slot="title">
              <i class="el-icon-coordinate"></i>
              <span>客户管理</span>
            </template>
            <el-menu-item index="/customer/tags">
              <i class="el-icon-price-tag"></i>
              <span>标签管理</span>
            </el-menu-item>
            <el-menu-item index="/customer/list">
              <i class="el-icon-user"></i>
              <span>客户管理</span>
            </el-menu-item>
          </el-submenu>

          <!-- 数据分析 -->
          <el-menu-item index="/analysis/index">
            <i class="el-icon-s-data"></i>
            <span>数据分析</span>
          </el-menu-item>

          <!-- 设置 -->
          <el-submenu index="setting">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>企业设置</span>
            </template>
            <el-menu-item index="/set/company">
              <i class="el-icon-document-copy"></i>
              <span>企业信息</span>
            </el-menu-item>
          </el-submenu>

          <!-- 全流量专用 -->
          <el-submenu index="qllcompany">
            <template slot="title">
              <i class="el-icon-office-building"></i>
              <span>全流量专用</span>
            </template>
            <el-menu-item index="/qllcompany/attestation">
              <i class="el-icon-open"></i>
              <span>企业认证 </span>
            </el-menu-item>
            <el-menu-item index="/qllcompany/replace">
              <i class="el-icon-connection"></i>
              <span>更新与管理 </span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>

      <!-- 内容页 -->
      <router-view class="router-view"></router-view>
    </el-main>
  </div>
</template>

<script>
import Header from '../components/Header'
export default {
  data(){
    return {
      drawer: false,
      currentPath: '',
      account: {
        name: '您还没有登录',
      }
    }
  },
  mounted() {
    this.setDefaultSlider()
  },
  watch: {
    '$route.path': function (){
      this.setDefaultSlider()
    }
  },
  methods: {
    // 设置到导航栏默认选中
    setDefaultSlider(){
      switch(this.$route.path){
        case '/article/edit':
        case '/article/data':
        case '/article/staff':
          this.currentPath = '/article/list';
          break;
        case '/member/role/set':
          this.currentPath = '/member/role';
          break;
        case '/shop/goods/add':
        case '/shop/goods/sort':
        case '/shop/goods/specs':
          this.currentPath = '/shop/goods/list';
          break;
        case '/task/edit':
        case '/task/chart':
          this.currentPath = '/task/list';
          break;
        case '/analysis/suvery':
        case '/analysis/list':
        case '/analysis/detail':
          this.currentPath = '/analysis/index';
          break;
        // case '/square/square':
        case '/square/companycard':
          this.currentPath = '/square/index';
          break;
        // case '/square/square':
        case '/opportunity/mlist':
          this.currentPath = '/opportunity/index';
          break;
        default: this.currentPath = this.$route.path;
      }
    }
  },
  components: {
    'zg-header': Header
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
  min-width: 1200px;
  height: calc(100% - 64px);
  padding: 0;
  .menu{
    float: left;
    width: 199px;
    height: 100%;
    overflow-x: hidden;
    background-color: #FFF;
    border-right: 1px solid rgb(210, 213, 222);
    ::v-deep ul{
      width: 200px;
      height: 100%;
    }
  }
}
.router-view{
  width: calc(100% - 220px);
  height: calc(100% - 20px)!important;
  padding: 10px;
  margin-left: 200px;
  background-color: #f8f9fa;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>